export default defineAppConfig({
  saasName: 'FSBD Demo',
  saasLink: 'https://demo.fsbd.ai',
  customSaas: false,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si la visibilidad es oculto o público se asigna internamente a Taohirata.'
    }
  },
  analyticsLooker: '7b2393fb-a61c-4eec-ac11-710d9abbe2d2',
  adify: {
    actions: ['Aumentar', 'Reducir', 'Pausar', 'Mantener', 'Revisar'],
    platforms: ['Google Ads', 'Facebook Ads'],
    vertical: false,
    country: false
  },
  savvywords: {
    customers: [
      {
        name: 'Demo',
        code: 'devmunity-advertorial',
        savvywordsDataset: 'SAVVYWORDS',
        googleadsDataset: 'GOOGLE_ADS',
        searchtermsBucket: 'keywords_analysis_demo'
      }
    ]
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      }
    ]
  }
})